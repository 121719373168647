import React, { useEffect, useRef, useState } from "react";
import Header from "../../common/Header";
import SendIcon from "../../../assets/SendIcon";
import axios from "axios";
import profileIcon from "../../../assets/ProfileIcon.svg"
import Finley from "../../../assets/FinleyLogo.svg"
import { useLocation, useNavigate } from "react-router";
import './style.css';
import CopyIcon from "../../../assets/CopyIcon";
import Footer from "../../common/Footer";


interface TempItem {
    type: string;
    msg: string;
}

const Chat = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { userId, query, name } = location.state || {};
    const [tempArray, setTempArray] = useState<TempItem[]>([]);
    const [inputValue, setInputValue] = useState<string>('');
    const fetchData = async () => {
        if (userId) {
            setTempArray(prevArray => [...prevArray, { type: 'You', msg: query }]);
            setTempArray(prevArray => [...prevArray, { type: 'Finley', msg: "" }]);
            setInputValue('');
            const formData = {
                query: query,
                user_id: userId
            };
            try {
                const response = await axios.post('https://api.boom.myquestis.com/ai-service/api/get_answer', formData);
                setTimeout(() => {
                    setTempArray((previousArr) => (previousArr.slice(0, -1)));
                    setTempArray(prevArray => [...prevArray, { type: 'Finley', msg: response?.data?.answer }]);
                }, 1000);
                setInputValue('');
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };
    const [executed, setExecuted] = useState(false);

    if (!executed && query != undefined) {
        fetchData();
        setExecuted(true);
    }


    const send = async (query: string) => {
        if (inputValue.trim() !== '') {
            setTempArray(prevArray => [...prevArray, { type: 'You', msg: query }]);
            setTempArray(prevArray => [...prevArray, { type: 'Finley', msg: "" }]);
            setInputValue('');
            const history = [];
            for (let i = 0; i < tempArray.length; i++) {
                if (tempArray[i].type === 'You' && tempArray[i + 1]?.type === 'Finley') {
                    history.push({
                        question: tempArray[i].msg,
                        response: tempArray[i + 1].msg
                    });
                }
            }
    
            const formData = {
                query: query,
                user_id: userId,
                history: history // Add history to the payload
            };
    
            try {
                const response = await axios.post('https://api.boom.myquestis.com/ai-service/api/get_answer', formData);
                setTimeout(() => {
                    setTempArray(prevArray => prevArray.slice(0, -1)); // Remove the loading message
                    setTempArray(prevArray => [...prevArray, { type: 'Finley', msg: response.data.answer }]);
                }, 1000);
                setInputValue('');
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };
    
    const containerRef: any = useRef(null);

    // Function to scroll the container to the bottom
    const scrollToBottom = () => {
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    };



    // Scroll to bottom when tempArray updates
    useEffect(() => {
        scrollToBottom();
    }, [tempArray]);

    // if (userId && query) {
    //     send();
    // }
    const onClickPlus = () => {
        setTempArray([])
    }
    const onClickLogo = () => {
        navigate(`/landingPage?userId=${userId}&fullName=${name}`);
    }
    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            send(inputValue);
        }
    };
    return (
        <div className=" ">
            <div className="px-5 hidden  lg:block"> <Header onClickPlus={onClickPlus} onClickLogo={onClickLogo} /></div>
            <div className="lg:flex lg:justify-center ">
                <div className="sm:relative flex flex-col justify-between lg:justify-end h-screen  lg:w-[800px] ">
                    <div className="px-5  sm:block lg:hidden"> <Header onClickPlus={onClickPlus} onClickLogo={onClickLogo} /></div>
                    <div ref={containerRef} className="overflow-hidden overflow-y-auto  scrollbar-hide scroll-container pb-24 lg" >

                        <div className="pb-14  lg:px-5 " >
                            {tempArray.map((item, index) => (
                                <div className="py-6  border-t border-b " key={index}>
                                    <div className=' px-5 flex items-center mb-3'>
                                        <img src={item.type == "You" ? profileIcon : Finley} alt="profileIcon" />
                                        <div className="text9 ml-2">{item.type}</div>
                                    </div>
                                    {item.msg == "" ?
                                        <div className="loader">
                                            <div className="loader-dot"></div>
                                            <div className="loader-dot"></div>
                                            <div className="loader-dot"></div>
                                        </div>
                                        : <div className="px-5 text3 text-wrap"> {item.msg}</div>}
                                    {item.type != "You" && <div className=" cursor-pointer px-5 items-center mt-4 flex ">
                                        <CopyIcon />
                                        <div className="ml-2 text9 text-copy">Copy</div>
                                    </div>
                                    }
                                </div>
                            ))}
                            <div className="absolute bg-background flex bottom-14 left-0 right-0 justify-center py-5 px-3 lg:px-0 ">
                                <textarea value={inputValue} onKeyDown={handleKeyDown} onChange={(event) => setInputValue(event.target.value)} placeholder=" Ask me anything..." className="  h-[48px] px-4 w-[74vw] py-3 flex-grow resize-none   rounded-[20px] " />
                                <div onClick={() => send(inputValue)} className=" rounded-[20px]  bg-white h-[48px] ml-2  px-4 flex items-center cursor-pointer"><SendIcon /></div>

                            </div>
                            <Footer />

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Chat;
