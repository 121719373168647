import React from 'react'
import PlusIcon from '../../assets/PlusIcon'
import EditIcon from '../../assets/EditIcon'
import logo from "../../assets/Logo2.svg";
import profileIcon from "../../assets/ProfileIcon.svg"
import SpendIcon from '../../assets/SpendIcon';
import SaveIcon from '../../assets/SaveIcon';
import ChatIcon from '../../assets/ChatIcon';
import GoalIcon from '../../assets/GoalIcon';
import InsightsIcon from '../../assets/InsightsIcon';
import BottomSpend from '../../assets/bottomSpend';
function Footer() {
    return (
        <div className='fixed  bg-white lg:flex lg:justify-center bottom-0 left-0 right-0 px-5 h-[58px] pt-3 pb-[50px] '>
            <div className=' flex  justify-between lg:w-[800px] '>
                <div >
                    <div className='flex  justify-center' > <BottomSpend /></div>
                    <div className='text5'>Spend</div>
                </div>
                <div >
                    <div className='flex  justify-center' > <SaveIcon /></div>
                    <div className='text5'>Save</div>
                </div>
                <div >
                    <div className='flex  justify-center' > <ChatIcon /></div>
                    <div className='text5 text-blue'>Chat</div>
                </div>
                <div >
                    <div className='flex  justify-center' > <GoalIcon /></div>
                    <div className='text5'>Goals</div>
                </div>
                <div >
                    <div className='flex  justify-center' > <InsightsIcon /></div>
                    <div className='text5'>Insights</div>
                </div>
            </div>
        </div>
    )
}

export default Footer