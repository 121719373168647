import React, { useState } from "react";
import Header from "../../common/Header";

import googleLogo from "../../../assets/googleIcon.svg"
import LockIcon from "../../../assets/LockIcon";
import EmailIcon from "../../../assets/EmailIcon";
import EditIcon from "../../../assets/EditIcon";
import Footer from "../../common/Footer";
import StarIcon from "../../../assets/StarIcon";
import RecomendIcon from "../../../assets/RecomendIcon";
import SpendIcon from "../../../assets/SpendIcon";
import SpendingIcon from "../../../assets/SpendingIcon copy";
import SendIcon from "../../../assets/SendIcon";
import { useLocation, useNavigate } from "react-router";
import ArrowIcon from "../../../assets/ArrowIcon";

const LandingPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get('userId');
    const [inputValue, setInputValue] = useState<string>('');
    const fullName = queryParams.get('fullName');
    const onClickPlus = () => {
        navigate("/Chat", { state: { userId: userId, name: fullName } });
    }
    const onClickLogo = () => {

    }
    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            navigate("/Chat", { state: { userId: userId, query: inputValue, name: fullName } });

        }
    };
    const selected = "flex cursor-pointer ml-3 hover:bg-black hover:text-white justify-center items-center mt-2  h-10 min-w-[217px]  rounded-3xl text-pill border border-pill "
    const firstPill = "flex  cursor-pointer  justify-center items-center mt-2 bg-blue h-10 min-w-[217px] lg:min-w-[262px] rounded-3xl text-white "
    const pills = ["My Recommendations", "Upcoming Bills", "Spend plans", "Debt plans"]
    const [selectedPill, setSelectedPill] = useState(0)


    return (
        <div >
            <div className="px-6"><Header onClickPlus={onClickPlus} onClickLogo={onClickLogo} /></div>
            <div className="px-6   flex flex-col justify-between   h-[92vh] pt-[62px]">
                <div className="flex justify-center">
                    <div className="w-[800px] lg:px-[20px] ">
                        <div className=" desktext6 mt-2 lg:text-left">Hello, {fullName || 'Jaafar'}. How can I help you today?</div>
                        <div>
                            <div className="flex mt-9">
                                <StarIcon />
                                <div className="text7  lg:desktext7 ml-1">Recommendations </div>
                            </div>
                            <div className="flex  lg:justify-center mt-[9px] lg:mt-5 border-b border-black  pb-3 items-center">
                                <div className="text8 lg:desktext8 max-w-[362px] lg:max-w-full ">Maximize Your Savings: Your employer matches 401(k) contributions up to $4,000 annually.&nbsp;&nbsp;</div>
                                <div className="cursor-pointer pr-5"><ArrowIcon /></div>
                            </div>
                            <div className="flex  lg:justify-center mt-[9px]  border-b border-black  pb-3 items-center">
                                <div className="text8 lg:desktext8 max-w-[362px] lg:max-w-full ">Build your safety net: Save at least 3-6 months' worth of living expenses in an emergency fund.</div>
                                <div className="cursor-pointer pr-5"><ArrowIcon /></div>
                            </div>
                            <div className="flex  lg:justify-center mt-[9px]  border-b border-black  pb-3 items-center">
                                <div className="text8 lg:desktext8 max-w-[362px] lg:max-w-full ">Optimize your spending:  Review your monthly expenses and identify areas where you can cut back.</div>
                                <div className="cursor-pointer pr-5"><ArrowIcon /></div>
                            </div>
                            <div className="flex  lg:justify-center mt-[9px]  border-b border-black  pb-3 items-center">
                                <div className="text8 lg:desktext8 max-w-[362px] lg:max-w-full ">Plan for your future:  Let’s estimate how much you need to save each month to achieve your retirement goals.</div>
                                <div className="cursor-pointer pr-5"><ArrowIcon /></div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="flex lg:justify-center  items-end text9 lg:desktext9 h-full">

                    <div className="w-screen lg:flex lg:flex-col lg:w-[800px] ">
                        <div className="lg:flex text8 lg:desktext8 ml-1">Get Started</div>

                        <div className="flex overflow-hidden  overflow-x-scroll scrollbar-hide lg:w-[760px] pr-2 w-[90vw]">
                            {pills.map((data: any, index: any) => {
                                const isSelected = index === selectedPill;
                                const iconColor = isSelected ? "#FFFFFF" : "#776F69";
                                const icons = [<RecomendIcon color="#F6F5F4" />, <RecomendIcon color={iconColor} />, <SpendIcon color={iconColor} />, <SpendingIcon color={iconColor} />];

                                return (
                                    <div onMouseEnter={() => { setSelectedPill(index) }}
                                        onMouseLeave={() => { setSelectedPill(-1) }} onClick={() => { setSelectedPill(index) }} className={index === 0 ? firstPill : selected}>
                                        {icons[index]}
                                        <div className="ml-2 ">{data}</div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className="flex lg:justify-center">
                    <div className="flex lg:max-w-[800px] justify-center  mt-4 pb-5">
                        <input onKeyDown={handleKeyDown} onChange={(event) => {
                            setInputValue(event.target.value);
                        }} type="text" placeholder=" Ask me anything..." className="  h-[48px] px-4 w-[74vw] lg:w-[90vw]  py-2   rounded-[20px] " />
                        <div onClick={() => {
                            navigate("/Chat", { state: { userId: userId, query: inputValue, name: fullName } });
                        }} className=" rounded-[20px]  bg-white h-[48px] ml-2  px-4 flex items-center cursor-pointer"><SendIcon /></div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>)
}

export default LandingPage;
















// extra code



// <div>
// <div className="flex lg:justify-center mt-9">
//     <StarIcon />
//     <div className="text7  lg:desktext7 ml-1">Recommendations </div>
// </div>
// <div className="flex lg:justify-between px-[50px] mt-[9px]  border-b border-black  pb-3 items-center">
//     <div className="text8 lg:desktext8 max-w-[362px] lg:max-w-[430px] "><span className="text4">Maximize Your Savings:</span> Your employer matches 401(k) contributions up to $4,000 annually.</div>
//     <div className="cursor-pointer"><ArrowIcon /></div>
// </div>
// <div className="flex lg:justify-between px-[50px] mt-[9px]  border-b border-black  pb-3 items-center">
//     <div className="text8 lg:desktext8 max-w-[362px] lg:max-w-[430px] "><span className="text4">Build your safety net:</span> Save at least 3-6 months' worth of living expenses in an emergency fund.</div>
//     <div className="cursor-pointer"><ArrowIcon /></div>
// </div>
// <div className="flex lg:justify-between px-[50px] mt-[9px]  border-b border-black  pb-3 items-center">
//     <div className="text8 lg:desktext8 max-w-[362px] lg:max-w-[430px] "><span className="text4">Optimize your spending:</span>  Review your monthly expenses and identify areas where you can cut back.</div>
//     <div className="cursor-pointer"><ArrowIcon /></div>
// </div>
// <div className="flex lg:justify-between px-[50px] mt-[9px]  border-b border-black  pb-3 items-center">
//     <div className="text8 lg:desktext8 max-w-[362px] lg:max-w-[430px] "><span className="text4">Plan for your future: </span>  Let’s estimate how much you need to save each month to achieve your retirement goals.</div>
//     <div className="cursor-pointer"><ArrowIcon /></div>
// </div>
// </div>