import React, { useState } from "react";
import Header from "../../common/Header";
import Logo from "../../../assets/SendIcon"; // You're importing Logo component but not using it
import logo from "../../../assets/Logo.png";
import googleLogo from "../../../assets/googleIcon.svg"
import LockIcon from "../../../assets/LockIcon";
import EmailIcon from "../../../assets/EmailIcon";
import { useNavigate } from "react-router";

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleLogin = () => {
        const users = [
            { userId: 5, email: 'jaafar.mothafer@questis.co', password: '@Fin!3y2024', lastName: "Mothafer", firstName: 'Jaafar' },
            { userId: 7, email: 'praveen.kumar@questis.co', password: '@Fin!3y2024', lastName: "Kumar", firstName: 'Praveen' },
            { userId: 3, email: 'joe.britton@questis.co', password: '@Fin!3y2024', lastName: "Britton", firstName: 'Joe' },
            { userId: 2, email: 'gali.kumar@questis.co', password: '@Fin!3y2024', lastName: "Kumar", firstName: 'Gali' },
            { userId: 5, email: 'ghoran@postroadgroup.com', password: 'gh@Fin!3y2024', lastName: "Horan", firstName: 'Gardner' }
        ];

        const user = users.find(user => user.email === email && user.password === password);

        if (user) {
            navigate(`/landingPage?userId=${user.userId}&fullName=${encodeURIComponent(user.firstName)}`);
            // navigate("/landingPage", { state: { userId: user.userId, fullName: user.fullName } });
        } else {
            setError('Invalid email or password');
        }
    };

    return (
        <div className="flex flex-col px-12 justify-center items-center h-screen">
            <img src={logo} height="40px"  width="100px" alt="Logo" />
            <div className="mt-24 relative">
                <input type="text" onChange={(e) => setEmail(e.target.value)} className="w-[343px] border-[1.5px] px-3 h-[56px] pl-14 border-black rounded-[20px]" placeholder="abc@example.com" />
                <EmailIcon />
            </div>
            <div className="mt-4">
                <div className="relative">
                    <input type="password" onChange={(e) => setPassword(e.target.value)} className=" w-[343px] border-[1.5px]  pl-14 px-3 h-[54px]  border-black rounded-2xl " placeholder="*********" />
                    <LockIcon />
                </div>
            </div>
            {error && <div className="text-red-500 mt-2">{error}</div>}
            <div className="text cursor-pointer text-blue mt-4 underline">
                Forgot Password?
            </div>
            <div onClick={() => { handleLogin() }} className="cursor-pointer bg-loginBg mt-4 text2 text-white px-8 py-2 rounded-3xl  ">
                Login
            </div>
            <div className="mt-16 border-t-2 pt-[15px]  w-full flex justify-center ">
                <div className="flex "> <div className="text3">Don’t have an account? </div>
                    <div className="text2 cursor-pointer text-blue ml-1 underline">Register</div></div>
            </div>
            <div className=" cursor-pointer text4 flex items-center mt-[15px] border-[1.5px] border-black rounded-2xl px-[30px] py-[10px]"><div className="mr-2"><img src={googleLogo} alt="googleLogo" /></div><div>Continue with Google</div></div>

        </div>
    )
}

export default Login;
