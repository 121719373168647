import React from 'react'
import PlusIcon from '../../assets/PlusIcon'
import EditIcon from '../../assets/EditIcon'
import logo from "../../assets/Logo.png";
import profileIcon from "../../assets/ProfileIcon.svg"
function Header(props: any) {
    return (

        <div className='bg-background py-3 flex items-center px-3 justify-between fixed top-0 left-0 right-0 z-10'>
            <div><PlusIcon /></div>
            <img width='57px' height='40px' onClick={() => { props.onClickLogo() }} src={logo} alt="Logo" />
            <div className='flex'>
                <div onClick={() => { props.onClickPlus([]) }} className='mr-3 cursor-pointer'>
                    <EditIcon />
                </div>
                <div className=''>
                    <img src={profileIcon} alt="profileIcon" />
                </div>
            </div>
        </div>
    )
}

export default Header