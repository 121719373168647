import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Login from './components/pages/Home/Login';
import LandingPage from './components/pages/LandingPage/LangingPage';
import Chat from './components/pages/chat/Chat';
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/landingPage" element={<LandingPage />} />
        <Route path="/Chat" element={<Chat />} />


      </Routes>
    </BrowserRouter>
  );
}

export default App;
